import React from "react"
import TLink from "../components/tlink";

import Layout from "../components/layout"

const IndexPage = () => (
    <Layout>
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            <div className='flex justify-center align-center mt-12 text-5xl font-bold tracking-wide text-charcoal-70'>
                Writing
            </div>

            <div className='flex justify-center align-center mt-24 text-xl tracking-wide text-charcoal-60'>
                <TLink href='/software-estimation/' title='SOFTWARE ESTIMATION' />
                <span className='ml-2'> - I'm writing first guide on the process of estimating features.</span>
            </div>
        </div>
    </Layout>
);

export default IndexPage